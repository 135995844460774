<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">开班统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl searchboxfl">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="培训机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                  size="small"
                  v-model="compId"
                  remote
                  :remote-method="getCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
              >
                <el-option
                    v-for="item in companyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编码"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span
                class="itemLabel"
                style="width:95px; font-size:14px; padding:0 0.5rem"
                >培训类型:</span
              >
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
          </div>

          <div class="searchbox">
            <div title="班级状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="width:80px">班级状态:</span>
              <el-select
                v-model="studystate"
                placeholder="请选择班级状态"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in studylist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="开班时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width:90px">开班时间:</span>
              <el-date-picker
                v-model="date"
                size="small"
                type="daterange"
                style="width:5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              />
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              ></el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData(1,true)"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="getExport()"
              >导出</el-button
            >
          </div>
        </div>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="statistics">
              <span>培训人数:{{ userNum }} <i v-if="!userNum" class="el-icon-loading"></i></span>
              <span>实名认证人数:{{ realNameRegistrationNum }} <i v-if="!realNameRegistrationNum" class="el-icon-loading"></i></span>
              <span>完成人数:{{ completeUserNum }} <i v-if="!completeUserNum" class="el-icon-loading"></i></span>
              <span>结业人数:{{ graduationUserNum }} <i v-if="!graduationUserNum" class="el-icon-loading"></i></span>
            </div>
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                fixed
                min-width="200px"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="200px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="120px"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
                width="150px"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="120"
              />
              <el-table-column
                label="开班时间"
                width="100px"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.startDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                width="100px"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.endDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="负责人"
                align="left"
                show-overflow-tooltip
                prop="projectUser"
              />
              <el-table-column
                label="负责人电话"
                align="left"
                show-overflow-tooltip
                prop="projectUserPhone"
                width="120"
              />
              <el-table-column
                label="培训状态"
                align="left"
                show-overflow-tooltip
                prop="projectState"
              >
                <template slot-scope="scope">
                  <span>
                    {{ $setDictionary("PROJECTSTATE", scope.row.projectState) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="学员总数"
                align="right"
                show-overflow-tooltip
                prop="projectPeople"
              />
              <el-table-column
                label="实名认证人数"
                align="right"
                show-overflow-tooltip
                width="100px"
                prop="authenticNum"
              />
              <el-table-column
                label="认证率"
                align="right"
                show-overflow-tooltip
                width="100px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.authenticRate + "%" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="学习人数"
                align="right"
                show-overflow-tooltip
                width="100px"
                prop="projectStudyNum"
              />
              <el-table-column
                label="学习率"
                align="right"
                show-overflow-tooltip
                width="100px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.studyRate + "%" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="学习完成人数"
                align="right"
                show-overflow-tooltip
                width="100px"
                prop="proCompleteNum"
              />
              <el-table-column
                label="完成率"
                align="right"
                show-overflow-tooltip
                width="100px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.projectStudyRate + "%" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="结业人数"
                align="right"
                show-overflow-tooltip
                width="100px"
                prop="graduationNum"
              />
              <el-table-column
                label="未结业成人数"
                align="right"
                show-overflow-tooltip
                width="100px"
              >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.projectPeople - scope.row.graduationNum }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="行业类型"
                align="left"
                show-overflow-tooltip
                width="100px"
                prop="industryNamePath"
              >
                <template slot-scope="scope">{{
                  scope.row.industryNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="岗位类型"
                align="left"
                show-overflow-tooltip
                width="100px"
                prop="postName"
              >
                <template slot-scope="scope">{{
                  scope.row.postName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="职业/工种"
                align="left"
                show-overflow-tooltip
                width="100px"
                prop="occupationNamePath"
              >
                <template slot-scope="scope">{{
                  scope.row.occupationNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
                width="100px"
                fixed="right"
                prop="trainLevelName"
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/classStart",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  doNotInit:true,
  data() {
    return {
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      areaId: "", //行政区划
      projectCode: "", //班级编码
      compId: null,
      companyList: [],
      compName: "", //机构名称
      projectName: "", //班级名称
      studystate: "", //班级状态
      date: "", // 开班时间
      endTime: [], //结束时间
      ruleForm: {
        Trainingtype: "", // 培训类型
      },
      studylist: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "50",
          label: "培训中",
        },
        {
          value: "60",
          label: "培训结束",
        },
      ],
      params: {},
      completeUserNum: 0,//完成人数
      graduationUserNum: 0,//结业人数
      userNum: 0,//培训人数
      realNameRegistrationNum: 0,//实名注册人数
      queryStatus:true,//查询状态
    };
  },
  created() {
    this.getareatree();
    this.getData(1,true)
    // this.getCrowdtypeList();
  },
 computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    clearParams() {
      this.params = {};
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //  getCrowdtypeList() {
    //   const studylist = this.$setDictionary("PROJECTSTATE", "list");
    //   const list = [];
    //   for (const key in studylist) {
    //     list.push({
    //       value: key,
    //       label: studylist[key]
    //     });
    //   }
    //   this.studylist = list;
    // },
    getData(pageNum = 1,flag=false) {
      if(!this.queryStatus&&flag){
        this.$message.warning('正在查询统计数据,请完成后再试！')
        return
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.date) {
        params.startDate = this.date[0];
        params.endDate = this.date[1];
      }
      if (this.endTime) {
        params.endDateStartLong = this.endTime[0];
        params.endDateEndLong = this.endTime[1];
      }
      if (this.studystate) {
        params.projectState = this.studystate;
      }
      if (this.compId || this.$route.query.compId) {
        params.compId = this.compId || this.$route.query.compId;
      }
      this.doFetch({
        url: "/run/project/offerProjectStatistic",
        params,
        pageNum,
      });
      if(flag){
        this.getcount(params);
      }
    },
    // 资源提供者1
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompAgencyCompanyList", { compName: query })
            .then((res) => {
              if (res.status == 0) {
                this.companyList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.companyList = [];
      }
    },
    getcount(params) {
      this.completeUserNum=null,//完成人数
      this.graduationUserNum=null,//结业人数
      this.userNum=null,//培训人数
      this.realNameRegistrationNum=null,//实名注册人数
      this.queryStatus = false
      Promise.all([
        this.$post('/run/project/user/statistics', params,3000,false),
        this.$post('/run/project/authentication/user/statistics', params,3000,false),
        this.$post('/run/project/user/complete/statistics', params,3000,false),
        this.$post('/run/project/user/graduation/statistics', params,3000,false),
        ])
      .then((res) => {
        this.userNum = res[0].data;
        this.realNameRegistrationNum = res[1].data;
        this.completeUserNum = res[2].data;
        this.graduationUserNum = res[3].data;
        this.queryStatus = true
      })
      .catch((err) => {
        this.queryStatus = true
        return;
      });
    },
    getExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {};
          if (this.projectCode) {
            params.projectCode = this.projectCode;
          }
          if (this.compName) {
            params.compName = this.compName;
          }
          if (this.areaId) {
            params.areaId = this.areaId;
          }
          if (this.params.trainFormId) {
            params.trainTypeId = this.params.trainFormId;
          }
          if (this.params.levelFormId) {
            params.trainLevelId = this.params.levelFormId;
          }
          if (this.params.occFormId) {
            params.occupationId = this.params.occFormId;
          }
          if (this.params.postFormId) {
            params.postId = this.params.postFormId;
          }
          if (this.params.industryFormId) {
            params.industryId = this.params.industryFormId;
          }
          if (this.projectName) {
            params.projectName = this.projectName;
          }
          if (this.date) {
            params.startDate = this.date[0];
            params.endDate = this.date[1];
          }
          if (this.endTime) {
            params.endDateStartLong = this.endTime[0];
            params.endDateEndLong = this.endTime[1];
          }
          if (this.studystate) {
            params.projectState = this.studystate;
          }
          this.$post("/run/project/async/export", params)
            .then((res) => {
              if (res.status == "0") {
                let list = [];
                list.push(res.data);
                for (let item of list) {
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              } else {
                this.$message.error(re.message);
              }
            })
            .catch((err) => {
              return;
            });
          // this.$post("/run/project/exportProjectStatistic", params).then(
          //   res => {
          //     if (res.status == "0") {
          //       window.open(res.data.url);
          //     }
          //   }
          // );
        })
        .catch(() => {});
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.statistics{
  margin-bottom: 10px;
  >span + span{
    margin-left: 10px;
  }
}
</style>
